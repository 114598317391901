import React from "react";
import MoreCustomerExperiencesEntryBlock from "@/components/blocks/MoreCustomerExperiencesEntryBlock";
import CustomerExperienceEntryBlock from "@/components/blocks/CustomerExperienceEntryBlock";
import TeamBlock from "@/components/blocks/TeamBlock";
import ContactServiceBlock from "../pages/Contact/ContactServiceBlock";
import HeadingBlock from "./HeadingBlock";
import OneImageBlock from "./OneImageBlock";
import ThreeImagesBlock from "./ThreeImagesBlock";
import TwoImagesBlock from "./TwoImagesBlock";
import ParagraphBlock from "./ParagraphBlock";
import QuoteBlock from "./QuoteBlock";
import { getMarginClass } from "./DynamicContentBlock/getMarginClass";
import ButtonBlock from "./ButtonBlock";
import PageHeaderTwo from "./PageHeaderTwo";
import PageHeaderWithIconButtons from "./PageHeaderWithIconButtons";
import HighlightsBlock from "./HighlightsBlock";
import PageLinksBlock from "./PageLinksBlock";
import SubjectOverviewBlock from "./SubjectOverviewBlock";
import FaqMiniBlock from "./FaqMiniBlock";
import ContactPersonBlock from "./ContactPersonBlock";
import CallToActionBlock from "./CallToActionBlock";
import CallToActionBlock2 from "./CallToActionBlock2";
import BlogsEntryBlock from "./BlogsEntryBlock";
import SpotlightBlogBlock from "./SpotlightBlogBlock";
import InformationTabsBlock from "./InformationTabsBlock";
import { filterProps } from "./DynamicContentBlock/filterProps";
import { DynamicContentItem, DynamicContentItemTypename } from "./DynamicContentBlock/types";
import SubHeaderLinksBlock from "./SubHeaderLinksBlock";
import CustomerExperienceCarousel from "./CustomerExperienceCarousel";
import PageHeaderThree from "./PageHeaderThree";
import BigUspBlock from "./BigUspBlock";
import OpenCookieNotificationLink from "./OpenCookieNotificationLink/OpenCookieNotificationLink";
import { WhitepaperRequestBlock } from "./WhitepaperRequestBlock/WhitepaperRequestBlock";
import StepByStepBlock from "./StepByStepBlock";
import EmployeeExperienceCarousel from "./EmployeeExperienceCarousel";
import PageHeader from "./PageHeader";
import CtaAboveHeader from "./CtaAboveHeader";
import IconsBlock from "./IconsBlock";
import { FaqOverviewBlock } from "./FaqBlock";
import { FaqSingleSubjectBlock } from "./FaqSingleSubjectBlock";
import { SubHeaderLinkCardsBlock } from "./SubHeaderLinksCardsBlock/SubHeaderLinkCardsBlock";
import { ScriptBlock } from "./ScriptBlock/ScriptBlock";
import HelloRiderBlock, { HelloRiderWidget } from "./HelloRider";
import { VideoBlock } from "./VideoBlock/VideoBlock";
import { PreprBlogProps } from "@/services/prepr/queries/getBlogs";

interface DynamicContentBlockProps {
  item: DynamicContentItem;
  prevTypename?: DynamicContentItemTypename;
  id?: number;
}

const DynamicContentBlock: React.FC<DynamicContentBlockProps> = (props) => {
  const { item, prevTypename } = filterProps(props);
  const marginClass = getMarginClass(item?.__typename, prevTypename);
  switch (item?.__typename) {
    case "PageHeader":
      return <PageHeader {...item} className={`${marginClass} tablet:mb-20`} />;
    case "PageHeaderTwo":
      return <PageHeaderTwo {...item} className={`${marginClass} tablet:mb-20`} />;
    case "PageHeaderThree":
      return <PageHeaderThree {...item} className={`${marginClass} tablet:mb-20`} />;
    case "PageHeaderWithIconButtons":
      return <PageHeaderWithIconButtons {...item} className={`${marginClass} tablet:mb-20`} />;
    case "BigUspBlock":
      return <BigUspBlock {...item} className={`${marginClass} tablet:mb-20`} />;
    case "ParagraphBlock":
      return <ParagraphBlock {...item} className={marginClass} />;
    case "HeadingBlock":
      return <HeadingBlock {...item} className={marginClass} />;
    case "QuoteBlock":
      return <QuoteBlock {...item} className={marginClass} />;
    case "OneImageBlock":
      return <OneImageBlock {...item} className={marginClass} />;
    case "TwoImagesBlock":
      return <TwoImagesBlock {...item} className={marginClass} />;
    case "ThreeImagesBlock":
      return <ThreeImagesBlock {...item} className={marginClass} />;
    case "ButtonBlock":
      return <ButtonBlock {...item} className={marginClass} />;
    case "HighlightsBlock":
      return <HighlightsBlock {...item} className={marginClass} />;
    case "PageLinksBlock":
      return <PageLinksBlock {...item} className={marginClass} />;
    case "SubjectOverviewBlock":
      return <SubjectOverviewBlock {...item} className={marginClass} />;
    case "CustomerExperienceEntryBlock":
      return <CustomerExperienceEntryBlock {...item} className={marginClass} />;
    case "MoreCustomerExperiencesEntryBlock":
      return <MoreCustomerExperiencesEntryBlock {...item} className={marginClass} />;
    case "FaqBlock":
      return <FaqMiniBlock {...item} className={marginClass} />;
    case "ContactPersonBlock":
      return <ContactPersonBlock {...item} className={marginClass} />;
    case "ContactServiceBlock":
      return <ContactServiceBlock {...item} className={marginClass} />;
    case "CallToActionBlock":
      return <CallToActionBlock {...item} className={marginClass} />;
    case "CallToActionBlock2":
      return <CallToActionBlock2 {...item} className={marginClass} />;
    case "TeamBlock":
      return <TeamBlock {...item} className={marginClass} />;
    case "BlogsEntryBlock":
      return (
        <BlogsEntryBlock
          {...(item as typeof item & {
            blogs: PreprBlogProps | undefined;
          })}
          className={marginClass}
        />
      );
    case "SpotlightBlogBlock":
      return <SpotlightBlogBlock {...item} className={marginClass} />;
    case "InformationTabsBlock":
      return <InformationTabsBlock {...item} className={marginClass} />;
    case "SubHeaderLinksBlock":
      return <SubHeaderLinksBlock {...item} className={marginClass} />;
    case "CustomerExperienceCarouselBlock":
      return <CustomerExperienceCarousel {...item} className={marginClass} />;
    case "EmployeeExperienceCarouselBlock":
      return <EmployeeExperienceCarousel {...item} className={marginClass} />;
    case "OpenCookieNotificationLink":
      return <OpenCookieNotificationLink {...item} className={marginClass} />;
    case "WhitepaperRequestBlock":
      return <WhitepaperRequestBlock {...item} className={marginClass} />;
    case "StepByStepBlock":
      return <StepByStepBlock {...item} className={marginClass} />;
    case "CtaAboveHeader":
      return <CtaAboveHeader {...item} className={marginClass} />;
    case "IconsBlock":
      return <IconsBlock {...item} className={marginClass} />;
    case "FaqOverviewBlock":
      return <FaqOverviewBlock {...item} className={marginClass} />;
    case "FaqSingleSubjectBlock":
      return <FaqSingleSubjectBlock {...item} className={marginClass} />;
    case "SubHeaderLinkCardsBlock":
      return <SubHeaderLinkCardsBlock {...item} className={marginClass} />;
    case "ScriptBlock":
      return (
        <ScriptBlock
          id={`script-block-${props.id}`}
          className={marginClass}
          input={item.input || ""}
        />
      );
    case "HelloRiderBlock":
      return (
        <HelloRiderBlock
          className={marginClass}
          type={item.hello_rider_widget_id as HelloRiderWidget}
        />
      );
    case "VideoBlock":
      return <VideoBlock {...item} className={marginClass} />;
    default:
      return null;
  }
};

export default DynamicContentBlock;
